export enum DiscountDuration {
    ONCE = 'once',
    FOREVER = 'forever',
    REPEATING = 'repeating'
}

export interface Discount {
    _id?: string;
    sourceIds?: {
        stripe: string;
        braintree: string;
    };
    amount: number;
    percentage: number;
    duration: DiscountDuration;
    durationMonths: number;
    isDeleted?: string;
    forPlan?: string;
}