import { Injectable } from '@angular/core';
import { DiscountDuration } from '../../models/discount.model';
import { Tag } from '../../models/tag.model';
import { Game } from '../constants/game.enum';

export enum TrackEvent {
    SUBSCRIBE = 'Subscribe',
    INIT_CHECKOUT = 'InitiateCheckout',
    VIEW_CONTENT = 'ViewContent',
    COMPLETE_REGISTRATION = 'CompleteRegistration'
}

@Injectable()
export class TrackService {
    constructor() {}

    public trackSubscribeEvent(data: {
        price: number;
        planName: string;
        planDuration: number;
        promoCode?: string;
        promoCodeDuration?: DiscountDuration | number;
        currentGame: Game | null | undefined;
    }) {
        // this.angulartics2GoogleTagManager.eventTrack(TrackEvent.SUBSCRIBE, {
        //     event: TrackEvent.SUBSCRIBE,
        //     gtmCustom: data
        // });
    }

    public trackInitCheckoutEvent(data: {
        price: number;
        planName: string;
        planDuration: number;
        referrerPromoCode: string;
        currentGame: Game | null | undefined;
    }) {
        // this.angulartics2GoogleTagManager.eventTrack(TrackEvent.INIT_CHECKOUT, {
        //     event: TrackEvent.INIT_CHECKOUT,
        //     gtmCustom: data
        // });
    }

    public trackViewContentEvent(data: {
        contentName: string;
        game: Game | string | undefined;
        contentId?: string;
        contentTag?: Tag | string;
    }) {
        // this.angulartics2GoogleTagManager.eventTrack(TrackEvent.VIEW_CONTENT, {
        //     event: TrackEvent.VIEW_CONTENT,
        //     gtmCustom: data
        // });
    }

    public trackCompleteRegistrationEvent(data: {
        currentGame: Game | null | undefined;
        referrerPromoCode: string;
    }) {
        // this.angulartics2GoogleTagManager.eventTrack(TrackEvent.COMPLETE_REGISTRATION, {
        //     event: TrackEvent.COMPLETE_REGISTRATION,
        //     gtmCustom: data
        // });
    }
}
